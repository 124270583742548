<template>
  <div>
    <div class="text-center q-mt-xl" style="height: 100px; width: 400px">
        <PaymentConfirmed/>
    </div>
  </div>
</template>

<script>
import PaymentConfirmed from '@/components/PaymentConfirmed'

export default {
    name: 'PaymentRecovered',
    components: {
        PaymentConfirmed
    }
}
</script>