
import './styles/quasar.sass'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Meta from 'quasar/src/plugins/Meta.js';import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    loading: {
      message: 'Performing transaction..',
      spinnerSize: 60,
      spinnerColor: 'red'
    }
  },
  plugins: {
    Loading,
    Dialog,
    Meta,
    Notify
  }
}