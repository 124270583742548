
<template>
  <q-layout view="hHh lpR fFf">
      <q-header elevated class="bg-white text-primary">
        <q-toolbar>
          <q-img src="/logo.png" style="width: 120px"/>
        </q-toolbar>
      </q-header>
    <q-page-container>
        <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'LayoutDefault',

  setup () {
    document.title = "Carbnb Payment";
    return {
      leftDrawerOpen: ref(false)
    }
  }
}
</script>
