<template>
    <q-card-section style="min-height: 80vh">
        <div class="text-h5 text-bold q-mt-sm q-pl-md">
            Booking Details
        </div>
        <q-img src="/line.png" style="height: 8px;"/>
        <div class="row q-ml-md q-mt-lg">
            <div class="col-3 text-bold q-py-xs q-px-xs">Booking Code</div>
            <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.bookingCode }}</div>
            <div class="col-3 text-bold q-py-xs q-px-xs">Name of Customer</div>
            <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.customerName }}</div>
            <div class="col-3 text-bold q-py-xs q-px-xs">Pick Up Date</div>
            <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.pickupDate }}</div>
            <div class="col-3 text-bold q-py-xs q-px-xs">Return Date</div>
            <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.estimatedReturnDate }}</div>
            <div class="col-3 text-bold q-py-xs q-px-xs">Declared Destination</div>
            <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.destination }}</div>
        </div>
        <div class="text-h5 text-bold q-mt-sm q-pl-md">
            Extension Details
        </div>
        <q-img src="/line.png" style="height: 8px;"/>
        <div class="row q-ml-md q-mt-lg">
            <div class="col-3 text-bold q-py-xs q-px-xs">Additional Hours</div>
            <div class="col-9 q-py-xs q-px-xs">+ {{ parseInt(this.invoice.extension.hours) }} hour(s)</div>
            <div class="col-3 text-bold q-py-xs q-px-xs">New Return Date</div>
            <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.extension.newReturnDate }}</div>        
            <div class="col-12 q-py-xs q-px-xs">
                <q-banner inline-actions rounded class="bg-primary text-white">
                This extension is temporary unless payment has been made. Please pay for the extension as soon as possible so that it will be applied to your active booking.
                </q-banner>
            </div>
        </div>
        <div class="text-h5 text-bold q-mt-sm q-pl-md">
            To Collect
        </div>
        <q-img src="/line.png" style="height: 8px;"/>
        <div class="row q-ml-md q-mt-lg">
            <div class="col-3 text-bold q-py-xs q-px-xs">Per Hour</div>
            <div class="col-9 q-py-xs q-px-xs">PHP {{ parseInt(this.invoice.extension.perHour) }}</div>
            <div class="col-3 text-bold q-py-xs q-px-xs">For {{ parseInt(this.invoice.extension.hours) }} hour(s)</div>
            <div class="col-9 q-py-xs q-px-xs">PHP {{ this.invoice.overallExtensionFee }}</div>
            <div class="col-3 text-bold q-py-xs q-px-xs">+ Other charges</div>
            <div class="col-9 q-py-xs q-px-xs">PHP {{ this.invoice.extensionInvoice.insurance_and_charges }}</div>
            <div class="col-3 text-bold q-py-xs q-px-xs">+ VAT Charge</div>
            <div class="col-9 q-py-xs q-px-xs">PHP {{ this.invoice.extensionInvoice.vat }}</div>                   
        </div>
        <div class="text-h5 text-bold q-mt-sm q-pl-md" v-if="!this.invoice.isPaid">
            Amount to Pay
        </div>
        <q-img src="/line.png" style="height: 8px;"/>
        <div class="text-center" v-if="!this.invoice.isPaid">
            <p class="text-h4 text-bold text-green q-ml-md q-mt-lg">PHP {{ this.invoice.extensionInvoice.reservation_fee }}</p>
        </div>
        <div class="text-center" v-if="!this.invoice.isPaid && this.showPaymentMethod">
            <p><b>Choose your preferred payment method</b></p>
             <q-radio id="payment-card" v-model="method" :val="paymentMethods.card"/> <q-img src="/payment-card.png" for="payment-card" style="width: 100px; height: 30px" />
            <q-radio id="payment-gcash" v-model="method" :val="paymentMethods.gcash" /> <q-img src="/payment-gcash.webp" style="width: 100px; height: 30px" />
        </div>
        <div class="text-center q-py-lg" v-if="!this.invoice.isPaid && this.showPaymentMethod">
            <q-btn color="red" style="width: 100px" label="Continue" @click="$emit('selected-payment-method', this.method)"/>
        </div>
    </q-card-section>
</template>

<script>
export default {
    name: 'PaymentDetailsPartialExtension',
    props: {
        showPaymentMethod: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            invoice: this.$store.state.rentalDetails,
            vehicle: this.$store.state.vehicle,
            method: this.constants.paymentMethods.gcash,
            paymentMethods: this.constants.paymentMethods,
            invoiceTypes: this.constants.invoiceTypes
        }
    }
}
</script>