<template>
  <div class="column justify-center items-center" style="height: 100%">
    <payment-details v-if="showPaymentDetails && !requiresInput"
      v-on:selected-payment-method="processSelectedPaymentMethod"
    />
    <payment-details-input v-if="requiresInput" v-on:details-submitted="getPaymentDetails" />
  </div>
</template>

<script>
import PaymentDetails from '@/components/PaymentHome/PaymentDetails'
import PaymentDetailsInput from '@/components/PaymentHome/PaymentDetailsInput'

export default {
  name: 'PaymentHome',
  components: {
    PaymentDetails,
    PaymentDetailsInput
  },
  data() {
    return {
      showPaymentDetails: false,
      requiresInput: false
    }
  },
  created() {
    // If no query params has been passed then prompt user to input their booking code and surname.
    if (this.$route.query.booking_code === undefined || this.$route.query.surname === undefined) {
      this.requiresInput = true;
      return;
    }

    // Connect to the server and get the payment details
    this.getPaymentDetails({
      bookingCode: this.$route.query.booking_code,
      surname: this.$route.query.surname
    })
  },
  methods: {
    /**
     * Take the user to the correct page for payment processing.
     */
    processSelectedPaymentMethod(method) {
      // Default will be gcash
      let type = 'source';

      // If the user selected to pay using visa/mastercard
      if (method === this.constants.paymentMethods.card) {
        type = 'intent';
      }

      // Toggle loading screen
      this.$q.loading.show({
        boxClass: 'bg-grey-2 text-grey-9'
      });

      // Dispatch the initialization process
      this.$store.dispatch('initializePayment', type).then((response) => {
        
        // Hide the loading screen on return
        this.$q.loading.hide();

        if (type === 'intent' && response.status === this.constants.paymentResponses.paymentIntentCreated) {
          this.$router.push({name: 'PaymentMethodCard'});
        }

        if (type === 'source' && response.status === this.constants.paymentResponses.pending) {
          window.location = response.checkoutUrl;
        }

        if (response.status === this.constants.paymentResponses.alreadyPaid) {
          this.$q.dialog({
            title: 'Booking Paid',
            message: 'This booking was already paid and there are no other pending payments.'
          }).onOk(() => {
            localStorage.clear();
          }).onCancel(() => {
            localStorage.clear();
          }).onDismiss(() => {
            localStorage.clear();
          })
        }
      }).catch(() => {
          this.$q.loading.hide();
          this.$q.dialog({
            title: 'There was a mistake..',
            message: 'It seems that we have encountered an unexpected error. Please try again later.'
          }).onOk(() => {
            localStorage.clear();
            this.$router.push({name: 'PaymentHome'});
          }).onCancel(() => {
            localStorage.clear();
            this.$router.push({name: 'PaymentHome'});
          }).onDismiss(() => {
            localStorage.clear();
            this.$router.push({name: 'PaymentHome'});
          })
      });
    },

    /**
     * Gets the payment details from the server.
     */
    getPaymentDetails(payload) {
      this.$q.loading.show({
        boxClass: 'bg-grey-2 text-grey-9',
        message: 'Getting booking details..'
      });
      // When created, get payment details from the API.
      this.$store.dispatch('getPaymentDetails', payload).then((response) => {
        // If the request is well received
        if (response === 'payment_request_retrieved') {
          this.showPaymentDetails = true;
          this.requiresInput = false;
          this.$q.loading.hide();
        }

        // If the request is well received but there are previous payments that already made
        // but the user might not be able to complete it (i.e internet connection lost, accidental browser closed during finalization etc.)
        if (response === 'payment_already_received') {
          this.$q.loading.show({
            boxClass: 'bg-grey-2 text-grey-9',
            message: 'Trying to recover previous payment process..'
          });

          // Connect to server and try to do the recovery process
          this.$store.dispatch('continue').then((response) => {
            this.$q.loading.hide();
            switch (response.status) {
              case 'success':
                this.$router.push({ name: 'PaymentRecovered' });
                break;
              case 'already_paid':
                this.$q.dialog({
                  title: 'Booking Paid',
                  message: 'This booking was already paid and there are no other pending payments.'
                }).onOk(() => {
                  localStorage.clear();
                  this.$router.push({name: 'PaymentHome'});
                }).onCancel(() => {
                  localStorage.clear();
                  this.$router.push({name: 'PaymentHome'});
                }).onDismiss(() => {
                  localStorage.clear();
                  this.$router.push({name: 'PaymentHome'});
                })
                break;
            }
          }).catch(() => {
              // If the recovery process was aborted or failed
              this.showPaymentDetails = true;
              this.requiresInput = false;
              this.$q.loading.hide();
          }).finally(() => {
            this.$q.loading.hide();
          })
        }

        // Handle other booking statuses
        if (response === 'booking_expired') {
          this.$q.loading.hide();
          this.$q.dialog({
            title: 'Booking Expired',
            message: 'This booking has already expired since the reservation fee was not paid within the given amount of time. Please try booking again.'
          }).onOk(() => {
            //
          }).onCancel(() => {
            //
          }).onDismiss(() => {
            //
          })
          this.showPaymentDetails = false;
          this.requiresInput = true;
        }

        if (response === 'booking_cancelled') {
          this.$q.loading.hide();
          this.$q.dialog({
            title: 'Booking Cancelled',
            message: 'This booking has been cancelled. If you think this is a mistake, please contact Carbnb.'
          }).onOk(() => {
            //
          }).onCancel(() => {
            // 
          }).onDismiss(() => {
            // 
          })
          this.showPaymentDetails = false;
          this.requiresInput = true;
        }

        if (response === 'booking_closed') {
          this.$q.loading.hide();
          this.$q.dialog({
            title: 'Booking Closed',
            message: 'This booking is already closed. If you need more details, please contact Carbnb.'
          }).onOk(() => {
            //
          }).onCancel(() => {
            //
          }).onDismiss(() => {
            //
          })
          this.showPaymentDetails = false;
          this.requiresInput = true;
        }
      }).catch((err) => {
        this.$q.loading.hide();
        if (err === 404) {
          this.$q.dialog({
            title: 'Booking Not Found',
            message: 'There are no booking found with the details you provided. Please check the booking code or surname.'
          }).onOk(() => {
            //
          }).onCancel(() => {
            //
          }).onDismiss(() => {
            //
          })
          this.showPaymentDetails = false;
          this.requiresInput = true;
        } else {
          this.$q.dialog({
            title: 'There was a mistake..',
            message: 'It seems that we have encountered an unexpected error. Please try again later.'
          }).onOk(() => {
            localStorage.clear();
            this.$router.push({name: 'Home'});
          }).onCancel(() => {
            localStorage.clear();
            this.$router.push({name: 'Home'});
          }).onDismiss(() => {
            localStorage.clear();
            this.$router.push({name: 'Home'});
          })
        }
      })
    }
  }
}
</script>