import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios'
import constants from '../const'

export default createStore({
  state: {
    // The current payment intent id stored inside the app.
    paymentIntentId: null,
    
    // The complete rental details of the client
    rentalDetails: {
      customerName: null,
      operatorName: null,
      operatorMobileNumber: null,
      bookingId: null,
      invoiceNumber: null,
      pickupDate: null,
      estimatedReturnDate: null,
      reservationFee: null,
      operatorFee: null,
      ratePerDay: null,
      serviceCharge: null,
      paymentGatewayFee: null,
      destination: null,
      isPaid: false,
      type: 1,
      insuranceAndOtherCharges: null
    },

    // The vehicle details
    vehicle: {
      vehicleName: null,
      vehicleBrand: null,
      vehicleType: null,
      vehiclePickupLocation: null
    }
  },
  mutations: {
    /**
     * Saves the payment details (invoice) to the state manager.
     *
     * @param { Object } state 
     * @param { Object } data 
     */
    savePaymentDetails(state, data) {
      /**
       * Save rental details
       */
      state.rentalDetails.bookingId = parseInt(data.booking_id);
      state.rentalDetails.bookingCode = data.booking_code;
      state.rentalDetails.invoiceNumber = data.invoice_number;
      state.rentalDetails.customerName = data.customer_name;
      state.rentalDetails.operatorName = data.operator_name;
      state.rentalDetails.operatorMobileNumber = data.operator_mobile_number;
      state.rentalDetails.pickupDate = data.pickup_date;
      state.rentalDetails.estimatedReturnDate = data.estimated_return_date;
      state.rentalDetails.reservationFee = parseFloat(data.reservation_fee);
      state.rentalDetails.operatorFee = parseFloat(data.operator_fee);
      state.rentalDetails.destination = data.destination;
      state.rentalDetails.serviceCharge = parseFloat(data.service_charge);
      state.rentalDetails.ratePerDay = parseFloat(data.rate_per_day);
      state.rentalDetails.paymentGatewayFee = parseFloat(data.payment_gateway_share);
      state.rentalDetails.duration = parseFloat(data.duration);
      state.rentalDetails.vatCharge = parseFloat(data.vat);
      if (state.rentalDetails.totalRentalFee) {
        state.rentalDetails.totalRentalFee = data.m_total_rental_fee;
      } else {
        state.rentalDetails.totalRentalFee = data.total_rental_fee;
      }
      state.rentalDetails.isPaid = data.is_paid;
      state.rentalDetails.type = data.type;
      state.rentalDetails.insuranceAndOtherCharges = data.insurance_and_charges;

      if (data.voucher_details) {
        state.rentalDetails.voucher = data.voucher_details;
      } else {
        state.rentalDetails.voucher = null;
      }

      if (data.carwash_fee) {
        state.rentalDetails.carwash_fee = data.carwash_fee;
      } else {
        state.rentalDetails.carwash_fee = null;
      }

      /**
       * Save vehicle details
       */
      state.vehicle.name = data.vehicle_name;
      state.vehicle.brand = data.vehicle_brand;
      state.vehicle.type = data.vehicle_type;
      state.vehicle.pickupLocation = data.vehicle_pickup_location;
      state.vehicle.year = data.vehicle_year;
      state.vehicle.seats = data.vehicle_seats;
      state.vehicle.transmission = data.transmission;

      // Check if there is an active extension for this booking
      if (data.extension) {
        // Swap the invoice type to extension
        state.rentalDetails.type = data.extensionInvoice.type;
        state.rentalDetails.extension = data.extension;
        state.rentalDetails.extensionInvoice = data.extensionInvoice;
        // Swap the current app reservation fee to the reservation fee of the extension
        state.rentalDetails.reservationFee = data.extensionInvoice.reservation_fee;
        state.rentalDetails.overallExtensionFee = data.extensionInvoice.overall_extension_fee;
        state.rentalDetails.insuranceAndOtherCharges = data.extensionInvoice.insurance_and_charges;
      }
    },

    /**
     * Updates the current payment intent id stored on the app.
     *
     * @param { Object } state 
     * @param { String } value 
     */
    updatePaymentIntentId(state, value) {
      state.paymentIntentId = value;
    },
  },
  actions: {
    /**
     * Gets payment details from the API.
     *
     * @param { Object } param0 
     * @param { Object } params 
     */
    getPaymentDetails({commit}, params) {
      // Clear any other details saved.
      localStorage.clear();

      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          baseURL: '', // To prevent axios from adding a forward / that produces 301
          url: process.env.VUE_APP_API_BASE_URL + '?booking_code=' + params.bookingCode + '&surname=' + params.surname // Appending the base URL here, only for GET requests.
        }).then((response) => {
          if (response.data['status'] == 'payment_request_retrieved' || response.data['status'] == 'payment_already_received') {
            commit('savePaymentDetails', response.data['data']['invoice']);
          }

          return resolve(response.data['status']);
        }).catch((e) => {
          return reject(e.response.status);
        });
      })
    },

    /**
     * Initializes the payment when the client has choose to either pay from
     * Card or Gcash.
     *
     * @param { Object } param0 
     * @param { Object } payload 
     */
    initializePayment({state, commit}, paymentType) {
      // If this invoice has attached extension that needs to be paid, we need to instead
      // submit the invoice number of the extension.
      let invoice = null;
      if (state.rentalDetails.type != constants.invoiceTypes.booking) {
        invoice = btoa(state.rentalDetails.extensionInvoice.invoice_number + ':' + state.rentalDetails.bookingId);
      } else {
        // If this is for the booking reservation, send the origin invoiceNumber instead of the extension invoice.
        invoice = btoa(state.rentalDetails.invoiceNumber + ':' + state.rentalDetails.bookingId)
      }
  
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: '/initialize-payment',
          data: {
            invoice: invoice,
            type: paymentType
          }
        }).then((response) => {
          // Retrieve the status
          var status = response.data['status'];
          
          // If the client chooses to pay thru intent (card)
          if (paymentType === 'intent') {
            if (status == constants.paymentResponses.paymentIntentCreated) {
              commit('updatePaymentIntentId', response.data['data']['payment_intent_id']);

              return resolve({
                status: constants.paymentResponses.paymentIntentCreated,
                paymentIntentId: state.paymentIntentId
              });
            } else if (status == constants.paymentResponses.alreadyPaid) {
              return resolve({
                status: constants.paymentResponses.alreadyPaid
              });
            }
          }

          // If the client chooses to pay thru source (source)
          if (paymentType === 'source') {
            if (status === constants.paymentResponses.paymentSourceCreated) {
              return resolve({
                status: constants.paymentResponses.pending,
                checkoutUrl: response.data['data']['checkoutUrl'],
                successUrl: response.data['data']['successUrl'],
                failedUrl: response.data['data']['failedUrl']
              });
            }
            if (status === constants.paymentResponses.alreadyPaid) {
              return resolve({
                status: constants.paymentResponses.alreadyPaid
              });
            }
          }
        }).catch((err) => {
          return reject(err)
        });
      });

    },

    /**
     * Creates a payment method. Submits card to Paymongo and returns
     * a payment method id.
     *
     * @param { Object } payload 
     */
    createPaymentMethod({state}, payload) {
      console.log(state);
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: 'https://api.paymongo.com/v1/payment_methods',
          data: {
            data: {
              attributes: {
                details: {
                  card_number: payload.cardDetails.number,
                  exp_month: Number(payload.cardDetails.expMonth),
                  exp_year: Number(payload.cardDetails.expYear),
                  cvc: String(payload.cardDetails.securityCode)
                },
                billing: {
                  name: payload.billingInformation.name,
                  email: payload.billingInformation.emailAddress,
                  phone: payload.billingInformation.mobileNumber
                },
                type: constants.paymentMethods.card
              }
            }

          }
        }).then((response) => {
          return resolve({
            status: 'card_submitted',
            paymentMethodId: response.data['data']['id']
          });
        }).catch((err) => {
          return reject(err);
        })
      });
    },

    /**
     * Attaches the current payment method create to the
     * payment intent.
     * 
     * This is the actual payment process. The app will
     * send an attempt to API and the API will send an
     * attempt to the gateway.
     *
     * @param { Object } payload 
     */
    attachPaymentMethod({state}, payload) {
      console.log(state);
      // If this invoice has attached extension that needs to be paid, we need to instead
      // submit the invoice number of the extension.
      let invoice = null;
      if (state.rentalDetails.type != constants.invoiceTypes.booking) {
        invoice = btoa(state.rentalDetails.extensionInvoice.invoice_number + ':' + state.rentalDetails.bookingId);
      } else {
        // If this is for the booking reservation, send the origin invoiceNumber instead of the extension invoice.
        invoice = btoa(state.rentalDetails.invoiceNumber + ':' + state.rentalDetails.bookingId)
      }
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: '',
          data: {
            invoice: invoice,
            paymentIntentId: payload.paymentIntentId,
            paymentMethodId: payload.paymentMethodId
          }
        }).then((response) => {
          var status = response.data['status'];
          // Payment already fulfilled
          if (status === 'payment_fulfilled') {
            return resolve({
              status: 'payment_fulfilled'
            });
          }

          // Payment already paid
          if (status === 'already_paid') {
            return resolve({
              status: 'already_paid'
            });
          }

          // If the system needs authentication verification
          if (status === constants.paymentResponses.authenticationRequired) {
            return resolve({
              status: constants.paymentResponses.authenticationRequired,
              url: response.data['data']['url']
            });
          }

          // If the system payment has been successfully made
          if (status === constants.paymentResponses.succeeded) {
            return resolve({
              status: constants.paymentResponses.succeeded
            });
          }

          // Failure - reset the payment steps
          if (status === constants.paymentResponses.paymentFailed) {
            return resolve({
              status: constants.paymentResponses.paymentFailed,
              message: response.data['data']['last_payment_error']
            });
          }

          // Still under process - needs a requery
          if (status === constants.paymentResponses.processing) {
            return resolve({
              status: constants.paymentResponses.processing
            });
          }
        }).catch((err) => {
          return reject(err);
        })
      });
    },

    /**
     * Checks the invoice payment_source_id for 
     * @param { Object } param0 
     */
    checkSource({state}) {
      // If this invoice has attached extension that needs to be paid, we need to instead
      // submit the invoice number of the extension.
      let invoiceNumber = null;
      if (state.rentalDetails.type != constants.invoiceTypes.booking) {
        invoiceNumber = state.rentalDetails.extensionInvoice.invoice_number;
      } else {
        // If this is for the booking reservation, send the origin invoiceNumber instead of the extension invoice.
        invoiceNumber = state.rentalDetails.invoiceNumber;
      }

      return new Promise((resolve, reject) => {
        axios({
          method: 'GET',
          url: '/get-source?invoice_number=' + invoiceNumber + '&booking_id=' + state.rentalDetails.bookingId,
        }).then((response) => {
          var status = response.data['status'];

          // If the system needs authentication verification
          if (status === constants.paymentResponses.chargeable) {
            return resolve({status: constants.paymentResponses.chargeable});
          }
          if (status === constants.paymentResponses.pending) {
            return resolve({
              status: constants.paymentResponses.pending,
              checkoutUrl: response.data['data']['checkoutUrl'],
              successUrl: response.data['data']['successUrl'],
              failedUrl: response.data['data']['failedUrl']
            });
          }

          return resolve({status: status});
        }).catch((err) => {
          return reject(err);
        })
      });
    },

    /**
     * Process the transaction of e-wallet payment.
     *
     * @param { Object } param0 
     */
    transact({state}) {
      // If this invoice has attached extension that needs to be paid, we need to instead
      // submit the invoice number of the extension.
      let invoice = null;
      if (state.rentalDetails.type != constants.invoiceTypes.booking) {
        invoice = btoa(state.rentalDetails.extensionInvoice.invoice_number + ':' + state.rentalDetails.bookingId);
      } else {
        // If this is for the booking reservation, send the origin invoiceNumber instead of the extension invoice.
        invoice = btoa(state.rentalDetails.invoiceNumber + ':' + state.rentalDetails.bookingId)
      }
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: '/transact',
          data: {
            invoice: invoice
          }
        }).then((response) => {
          var status = response.data['status'];

          return resolve({status: status});

        }).catch((err) => {
          return reject(err);
        })
      });
    },

    /**
     * The continuation call of the payment intent method after the client authenticates their
     * card.
     *
     * @param { Object } param0
     */
    continue({state}) {
      // If this invoice has attached extension that needs to be paid, we need to instead
      // submit the invoice number of the extension.
      let invoice = null;

      if (state.rentalDetails.type != constants.invoiceTypes.booking) {
        invoice = btoa(state.rentalDetails.extensionInvoice.invoice_number + ':' + state.rentalDetails.bookingId);
      } else {
        // If this is for the booking reservation, send the origin invoiceNumber instead of the extension invoice.
        invoice = btoa(state.rentalDetails.invoiceNumber + ':' + state.rentalDetails.bookingId)
      }
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: '/continue-payment',
          data: {
            invoice: invoice
          }
        }).then((response) => {
          var status = response.data['status'];

          return resolve({status: status});
        }).catch((err) => {
          return reject(err);
        })
      });
    }
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
