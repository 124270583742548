import { createRouter, createWebHistory } from 'vue-router'
import PaymentHome from '../views/PaymentHome.vue'
import PaymentMethodCard from '../views/PaymentMethodCard.vue'
import PaymentRecovered from '../views/PaymentRecovered.vue'
import PaymentEwalletConfirm from '../views/PaymentEwalletConfirm.vue'
import PaymentAuthOK from '../views/PaymentAuthOK.vue'
import PaymentCommissionFee from '../views/PaymentCommissionFee.vue'

const routes = [
  {
    path: '/',
    name: 'PaymentHome',
    component: PaymentHome
  },
  {
    path: '/card-details',
    name: 'PaymentMethodCard',
    component: PaymentMethodCard
  },
  {
    path: '/recovered',
    name: 'PaymentRecovered',
    component: PaymentRecovered
  },
  {
    path: '/ew-confirm',
    name: 'eWalletConfirm',
    component: PaymentEwalletConfirm
  },
  {
    path: '/auth-ok',
    name: 'authOK',
    component: PaymentAuthOK
  },
  {
    path: '/ocf',
    name: 'PaymentCommissionFee',
    component: PaymentCommissionFee
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
