import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import constants from './const'

const app = createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(router)
    .use(store)

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.common['X-Papi-Key'] = process.env.VUE_APP_PAPI_KEY;
axios.defaults.headers.common['Authorization'] = 'Basic ' + btoa(process.env.VUE_APP_PM_PUBLIC_KEY);
axios.defaults.headers.common['X-AppPlatform'] = 'website';
app.config.globalProperties.axios = axios
app.config.globalProperties.constants = constants
app.mount('#app');
