<template>
    <q-form
        @submit="onSubmit"
        @reset="onReset"
    >
        <q-card class="text-center" flat style="width: 100%">
            <q-card-section style="min-height: 60vh">
                <div class="row">
                    <div class="col-12 q-pa-md">
                        <div class="text-h5 text-bold q-mt-sm q-pl-md">
                            Booking and Extension Payment
                        </div>
                        <q-img src="/line.png" style="height: 8px;"/>
                        <div class="w-100 q-pa-md">Enter your <b>Booking Code</b> and <b>Surname</b> to pay for your reservation or for any booking extensions.</div>
                    </div>
                    <div class="col-12 q-pa-md">
                        <q-input 
                            outlined 
                            v-model="bookingCode" 
                            label="Booking Code"
                            mask="A#A#A#A#"
                            :error="errorBookingCode"
                            :error-message="errorBookingCodeText"
                        />
                    </div>
                    <div class="col-12 q-pa-md">
                    <q-input 
                        outlined 
                        v-model="surname" 
                        label="Surname"
                        :error="errorSurname"
                        :error-message="errorSurnameText"
                    />
                    </div>
                </div>
            </q-card-section>
            <q-card-actions align="center">
                <q-btn color="white" text-color="black" type="reset" label="Reset" />
                <q-btn color="red" style="width: 200px" class="q-ml-md" text-color="white" type="submit" label="Submit" />
            </q-card-actions>
        </q-card>
    </q-form>
</template>

<script>
export default {
    name: 'PaymentDetailsInput',
    data() {
        return {
            bookingCode: null,
            surname: null,
            errorBookingCode: false,
            errorBookingCodeText: 'The booking code is required.',
            errorSurname: false,
            errorSurnameText: "The booking's customer surname is required."
        }
    },
    created() {
        if (this.$route.query.booking_code !== undefined) {
            var code = this.$route.query.booking_code;
            if (code.length === 8) {
                this.bookingCode = code;
            } 
        }
        if (this.$route.query.surname !== undefined) {
            var surname = this.$route.query.surname;
            if (surname.length !== 0) {
                this.surname = surname;
            } 
        }
    },
    methods: {
        onReset() {
            this.bookingCode = null;
            this.surname = null;
        },
        onSubmit() {
            this.errorBookingCode = false;
            this.errorSurname = false;

            // Run the null validation
            if (this.bookingCode === null && this.surname === null) {
                this.errorBookingCode = true;
                this.errorSurname = true;
                return;
            }

            if (this.bookingCode === null) {
                this.errorBookingCode = true;
            }
            if (this.surname === null) {
                this.errorSurname = true;
            }

            if (this.errorSurname || this.errorBookingCode) {
                return;
            }

            // Run with value validation
            if (this.bookingCode.length < 8) {
                this.errorBookingCode = true;
                this.errorBookingCodeText = 'The booking code must be 8 characters in length.';
            }

            if (this.surname.length == 0) {
                this.errorSurname = true;
                this.errorSurnameText = 'Please enter your surname';
            }

            if (this.errorSurname || this.errorBookingCode) {
                return;
            }

            return this.$emit('details-submitted', {
                bookingCode: this.bookingCode,
                surname: this.surname
            });
        }
    }
}
</script>