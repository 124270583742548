<template>
  <p>Validating transaction..</p>
</template>

<script>
export default {
    name: 'PaymentAuthOK',
    created() {
      window.parent.postMessage('3DS-authentication-complete', '*');
    }
}
</script>