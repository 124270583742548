<template>
  <div class="column justify-center items-center" style="height: 100%">
    <q-card v-if="isFound" flat bordered class="q-mt-lg" style="min-width: 600px">
        <q-card-section>
            <div class="text-h5 text-bold q-mt-sm q-pl-md">
                Hub Subscription Fee
            </div>
            <q-img src="/line.png" style="height: 8px;"/>
            <div class="row q-ml-md q-mt-lg">
                <div class="col-3 text-bold q-py-xs q-px-xs">Status</div>
                <div class="col-9 q-py-xs q-px-xs text-right">
                    <q-badge aligned="middle"
                        :color="statusBadge.color"
                        :text-color="statusBadge.text_color"
                        :label="data.status"
                    />
                </div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Operator Name</div>
                <div class="col-9 q-py-xs q-px-xs text-right">{{ data.operator_name }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">For the month of</div>
                <div class="col-9 q-py-xs q-px-xs text-right">{{ data.month }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Vehicle</div>
                <div class="col-9 q-py-xs q-px-xs text-right">{{ data.vehicle_name }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Plate Number</div>
                <div class="col-9 q-py-xs q-px-xs text-right">{{ data.plate_number }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Yard</div>
                <div class="col-9 q-py-xs q-px-xs text-right">{{ data.yard_name }}</div>
            </div>
            <q-img src="/line.png" style="height: 8px;"/>
            <div class="row q-ml-md q-mt-lg">
                <div class="col-3 text-bold q-py-xs q-px-xs">Hub Subscription Fee</div>
                <div class="col-9 q-py-xs q-px-xs text-right">{{ data.amount }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Payment Gateway Fee</div>
                <div class="col-9 q-py-xs q-px-xs text-right">+ {{ data.payment_gateway_fee }}</div>
            </div>
            <q-img src="/line.png" style="height: 8px;"/>
            <div class="text-center q-py-xl">
                <h4 class="text-positive" style="margin: 0">{{ data.total_amount }}</h4>
                <p>Total Subscription Fee</p>
            </div>
            <div v-if="!isPaid">
                <q-img src="/line.png" style="height: 8px;"/>
                <div class="text-center q-mt-md">
                    <p><b>Choose your preferred payment method</b></p>
                    <q-radio id="payment-card" v-model="method" val="card"/> <q-img src="/payment-card.png" for="payment-card" style="width: 100px; height: 30px" />
                    <q-radio id="payment-gcash" v-model="method" val="gcash" /> <q-img src="/payment-gcash.webp" style="width: 100px; height: 30px" />
                </div>
                <div class="text-center q-py-lg">
                    <q-btn
                        color="red"
                        style="width: 100px"
                        label="Continue"
                        @click="selectPaymentMethod"
                        :loading="isLoading"
                    />
                </div>
            </div>

        </q-card-section>
    </q-card>
    <q-dialog v-model="showCardInput" persistent>
      <q-card v-if="isEnteringCardDetails">
        <q-card-section class="row items-center">
            <div class="text-h5 text-bold q-mt-sm q-pl-md q-py-md">
                Enter Card Details <q-img src="/payment-card.png" for="payment-card" style="width: 100px; height: auto" />
            </div>
            <q-img src="/line.png" style="height: 8px;"/>
                <div class="row q-mt-md">
                    <div class="col-12 q-pa-sm">
                    <q-input 
                        outlined 
                        v-model="cardDetails.number.value" 
                        label="Enter Card Number" 
                        :dense="dense" 
                        mask="################"
                        fill-mask=" "
                        :error="cardDetails.number.error != null"
                        :error-message="cardDetails.number.error"
                        :disable="!allowInputs" 
                    />
                    </div>
                    <div class="col-md-4 col-6 q-pa-sm">
                    <q-input 
                        outlined 
                        v-model="cardDetails.expMonth.value" 
                        label="Expiration Month" 
                        :dense="dense" 
                        mask="##"
                        fill-mask=" "
                        :error="cardDetails.expMonth.error != null"
                        :error-message="cardDetails.expMonth.error"
                        :disable="!allowInputs"
                    />
                    </div>
                    <div class="col-md-4 col-6 q-pa-sm">
                    <q-input 
                        outlined 
                        v-model="cardDetails.expYear.value" 
                        label="Expiration Year" 
                        :dense="dense" 
                        mask="##"
                        fill-mask=" "
                        :error="cardDetails.expYear.error != null"
                        :error-message="cardDetails.expYear.error"
                        :disable="!allowInputs" 
                    />
                    </div>
                    <div class="col-md-4 col-12 q-pa-sm">
                    <q-input 
                        outlined 
                        v-model="cardDetails.securityCode.value" 
                        label="Security Code (CVC)"
                        :dense="dense" 
                        mask="###"
                        fill-mask=" "
                        :error="cardDetails.securityCode.error != null"
                        :error-message="cardDetails.securityCode.error"
                        :disable="!allowInputs"                     
                    />
                    </div>
                </div>
                    <q-banner class="bg-red text-white q-mt-md" v-if="genericErrorMessage != null">
                        <template v-slot:avatar>
                        <q-icon name="payment" color="white" />
                        </template>
                        {{ genericErrorMessage }}
                        <template v-slot:action>
                        <q-btn flat color="white" label="Confirm" @click="genericErrorMessage = null" />
                        </template>
                    </q-banner>
        </q-card-section>

        <q-card-actions align="right">
            <q-btn color="white" :disable="isLoading" text-color="black" v-close-popup label="Cancel" />
            <q-btn color="red" :loading="isLoading" class="q-ml-sm q-mr-md" @click="submitCard" label="Pay Now" />
        </q-card-actions>
      </q-card>
      <q-card v-else>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6"><q-icon name="lock"/> Authentication</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-card-section style="min-width: 400px">
            <iframe
                :src="cardAuthUrl"
                width="100%"
                height="400"
                frameborder="0"
            >
            </iframe>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showGcashAuth" persistent>
        <q-card>
            <q-card-section style="min-width: 400px">
                <iframe
                    :src="gcashAuthUrl"
                    width="100%"
                    height="400"
                    frameborder="0"
                >
                </iframe>
            </q-card-section>
        </q-card>
    </q-dialog>
  </div>
</template>
<script setup>
    import { ref } from 'vue';
    import axios from 'axios'
    import { useRoute, useRouter } from 'vue-router';
    import { useQuasar } from 'quasar'

    const method = ref('gcash');
    const showCardInput = ref(false);
    const showGcashAuth = ref(false);
    const gcashAuthUrl = ref(null);
    const cardAuthUrl = ref(null);
    const isEnteringCardDetails = ref(true);
    const allowInputs = ref(true);
    const isLoading = ref(false);
    const paymentMethodId = ref(null);
    const genericErrorMessage = ref(null);
    const route = useRoute();
    const router = useRouter();
    const $q = useQuasar();
    const statusBadge = ref({
        color: 'blue',
        text_color: 'white',
    });
    const data = ref({
        operator_name: '',
        vehicle_name: '',
        amount: '',
        status: '',
        payment_gateway_fee: '',
        total_amount: '',
        yard_name: '',
        plate_number: '',
        month: ''
    });
    const commissionFeeId = route.query.id;
    const surname = route.query.surname;
    const isPaid = ref(false);
    const isFound = ref(false);

    window.addEventListener('message', (ev) => {
        if (ev.data === '3DS-authentication-complete') {
            showGcashAuth.value = false;
            gcashAuthUrl.value = null;
            isEnteringCardDetails.value = true;
            proceedPayment();
        }
    });

    const cardDetails = ref({
        number: {
            value: null,
            attribute: 'Card Number',
            error: null
        },
        expMonth: {
            value: null,
            attribute: 'Expiration Month',
            error: null
        },
        expYear: {
            value: null,
            attribute: 'Expiration Year',
            error: null
        },
        securityCode: {
            value: null,
            attribute: 'Security Code',
            error: null
        }
    });

    if (!commissionFeeId || !surname) {
        $q.dialog({
            title: 'Commission Fee',
            message: 'Incomplete query params value. Please try again.'
        });
        router.push('/');
    } else {
        retrieveCommissionFee(commissionFeeId, surname);
    }

    function loadCommissionFeeData(response) {
        data.value.operator_name = response.data.data['operator_name'];
        data.value.vehicle_name = response.data.data['vehicle_name'];
        data.value.yard_name = response.data.data['yard_name'];
        data.value.plate_number = response.data.data['plate_number'];
        data.value.amount = response.data.data['label_amount'];
        data.value.payment_gateway_fee = response.data.data['label_payment_gateway_fee'];
        data.value.total_amount = response.data.data['label_total_amount'];
        data.value.month = response.data.data['label_month'];
        data.value.status = response.data.data['label_status'];
        if (response.data.data['status'] == 0
            || response.data.data['status'] == 4
            || response.data.data['status'] == 5
            || response.data.data['status'] == 6
        ) {
            isPaid.value = true;
        }

        switch (response.data.data['status']) {
            case '0':
                statusBadge.value.color = 'red';
                statusBadge.value.text_color = 'white';
                break;
            case '4':
                statusBadge.value.color = 'positive';
                statusBadge.value.text_color = 'white';
                break; 
            case '5':
                statusBadge.value.color = 'positive';
                statusBadge.value.text_color = 'white';
                break;  
            case '6':
                statusBadge.value.color = 'warning';
                statusBadge.value.text_color = 'black';
                break;     
            case '2':
                statusBadge.value.color = 'blue';
                statusBadge.value.text_color = 'white';
                break;     
        }
    }

    function retrieveCommissionFee(commissionFeeId, surname) {
        $q.loading.show({
            message: 'Loading commission fee..'
        });

        axios({
            method: 'GET',
            url: process.env.VUE_APP_API_COMM_FEE_BASE_URL + '?id=' + commissionFeeId + '&surname=' + surname,
        }).then((response) => {
            loadCommissionFeeData(response);
            isFound.value = true;
        }).catch((err) => {
            if (err.response) {
                if (err.response.status === 404) {
                    $q.notify({
                        message: 'The commission fee that you are trying to access was not found.',
                        icon: 'warning',
                        color: 'negative'
                    })
                    router.push('/');
                } else {
                    $q.notify({
                        message: 'There was an error while trying to process your request. Please try again later.',
                        icon: 'warning',
                        color: 'negative'
                    })
                    router.push('/');
                }
            }
        }).finally(() => {
            $q.loading.hide();
        })

    }
    function selectPaymentMethod() {
        if (method.value == 'card') {
            showCardInput.value = true;
            isEnteringCardDetails.value = true;
        }

        if (method.value == 'gcash') {
            submitGcash();
        }
    }

    function submitCard() {
        isLoading.value = true;
        resetValidations();
        axios({
            method: 'POST',
            url: 'https://api.paymongo.com/v1/payment_methods',
            data: {
                data: {
                    attributes: {
                        details: {
                            card_number: cardDetails.value.number.value,
                            exp_month: Number(cardDetails.value.expMonth.value),
                            exp_year: Number(cardDetails.value.expYear.value),
                            cvc: cardDetails.value.securityCode.value
                        },
                        type: 'card'
                    }
                }
            }
        }).then((response) => {
            paymentMethodId.value = response.data['data']['id'];
            method.value = response.data['data']['attributes']['type'];
            proceedPayment();
        }).catch((err) => {
            allowInputs.value = true;
            isLoading.value = false;
            if (err.response.status == 400) {
                handleCardErrors(err.response.data.errors)
            } else {
                this.$q.dialog({
                    title: 'Card Send Failed',
                    message: 'The card details was not successfully sent to the payment processor. Please try again later.'
                });
            }
        })
    }

    function submitGcash() {
        isLoading.value = true;
        resetValidations();
        axios({
            method: 'POST',
            url: 'https://api.paymongo.com/v1/payment_methods',
            data: {
                data: {
                    attributes: {
                        type: 'gcash'
                    }
                }
            }
        }).then((response) => {
            paymentMethodId.value = response.data['data']['id'];
            method.value = response.data['data']['attributes']['type'];
            proceedPayment();
        }).catch(() => {
            allowInputs.value = true;
            isLoading.value = false;
            this.$q.dialog({
                title: 'There has been a mistake',
                message: 'There was an error while trying to initialize payment. Please try again later.'
            });
        });
    }

    function handleCardErrors(errors) {
        errors.forEach((error) => {
            switch (error.source.attribute) {
                case 'card_number':
                    cardDetails.value.number.error = error.detail.replace(
                        error.source.pointer,
                        cardDetails.value.number.attribute
                    );
                    break;
                case 'exp_month':
                    cardDetails.value.expMonth.error = error.detail.replace(
                        error.source.pointer,
                        cardDetails.value.expMonth.attribute
                    );
                    break;
                case 'exp_year':
                    cardDetails.value.expYear.error = error.detail.replace(
                        error.source.pointer,
                        cardDetails.value.expYear.attribute
                    );
                    break; 
                case 'cvc':
                    cardDetails.value.securityCode.error = error.detail.replace(
                        error.source.pointer,
                        cardDetails.value.securityCode.attribute
                    );
                    break;                   
            }
        });
    }

    function resetValidations() {
        cardDetails.value.number.error = null;
        cardDetails.value.expYear.error = null;
        cardDetails.value.expMonth.error = null;
        cardDetails.value.securityCode.error = null;
        genericErrorMessage.value = null;
    }

    function proceedPayment() {
        isLoading.value = true;
        $q.loading.show({
            message: 'Processing payment..'
        });
        axios({
            method: 'POST',
            url: process.env.VUE_APP_API_COMM_FEE_BASE_URL + '/pay',
            data: {
                id: commissionFeeId,
                payment_method_id: paymentMethodId.value
            }
        }).then((response) => {
            if (response.data['status'] == 'awaiting_next_action') {
                switch (method.value) {
                    case 'gcash':
                        gcashAuthUrl.value = response.data['data']['url'];
                        showGcashAuth.value = true;
                        break;
                    case 'card':
                        isEnteringCardDetails.value = false;
                        cardAuthUrl.value = response.data['data']['url'];
                        break;
                }
            } else if (response.data['status'] == 'paid') {
                loadCommissionFeeData(response);
                isPaid.value = true;
                showCardInput.value = false;
                $q.notify({
                    message: 'Thank you! Your payment has been successfully received. A notification regarding your payment will be sent via email or SMS.',
                    icon: 'check',
                    color: 'positive'
                });
            }
        }).catch((err) => {
            if (err.response) {
                if (err.response.status == 500) {
                    $q.notify({
                        message: 'There was an error while trying to process your request. Please try again later.',
                        icon: 'warning',
                        color: 'negative'
                    })
                } else if (err.response.status == 400) {
                    if (method.value == 'card') {
                        genericErrorMessage.value = err.response.data['data']['message'];
                    }
                }
            }
        }).finally(() => {
            isLoading.value = false;
            $q.loading.hide();
        });
    }
</script>
