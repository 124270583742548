<template>
        <q-card-section style="min-height: 80vh">
            <div class="text-h5 text-bold q-mt-sm q-pl-md">
                Booking Details
            </div>
            <q-img src="/line.png" style="height: 8px;"/>
            <div class="row q-ml-md q-mt-lg">
                <div class="col-3 text-bold q-py-xs q-px-xs">Booking Code</div>
                <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.bookingCode }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Name of Customer</div>
                <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.customerName }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Pick Up Date</div>
                <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.pickupDate }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Return Date</div>
                <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.estimatedReturnDate }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Declared Destination</div>
                <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.destination }}</div>
            </div>
            <div class="text-h5 text-bold q-mt-sm q-pl-md">
                Vehicle Details
            </div>
            <q-img src="/line.png" style="height: 8px;"/>
            <div class="row q-ml-md q-mt-lg">
                <div class="col-3 text-bold q-py-xs q-px-xs">Vehicle</div>
                <div class="col-9 q-py-xs q-px-xs">{{ this.vehicle.brand }} {{ this.vehicle.name }} {{ this.vehicle.year }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Seating Capacity</div>
                <div class="col-9 q-py-xs q-px-xs">{{ this.vehicle.seats }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Transmission</div>
                <div class="col-9 q-py-xs q-px-xs">{{ this.vehicle.transmission }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Type</div>
                <div class="col-9 q-py-xs q-px-xs">{{ this.vehicle.type }}</div>
                <div class="col-3 text-bold q-py-xs q-px-xs">Vehicle Rate</div>
                <div class="col-9 q-py-xs q-px-xs">PHP {{ this.invoice.ratePerDay }}</div>
            </div>
            <div class="text-h5 text-bold q-mt-sm q-pl-md">
                Rental Price Breakdown
            </div>
            <q-img src="/line.png" style="height: 8px;"/>
            <div class="row q-ml-md q-mt-lg">
                <div class="col-5 text-bold q-py-xs q-px-xs">{{ this.vehicle.type }} for {{ this.invoice.duration }} day(s)</div>
                <div class="col-7 q-py-xs q-px-xs">PHP {{ this.invoice.ratePerDay }}</div>
                <div class="col-5 text-bold q-py-xs q-px-xs">VAT and other charges</div>
                <div class="col-7 q-py-xs q-px-xs">+ PHP {{ this.invoice.insuranceAndOtherCharges }}</div>
                <div class="col-5 text-bold q-py-xs q-px-xs" v-if="this.invoice.voucher">Voucher ({{ this.invoice.voucher.code }})</div>
                <div class="col-7 q-py-xs q-px-xs" v-if="this.invoice.voucher">- PHP {{ this.invoice.voucher.value }}</div>
                <div class="col-5 text-bold q-py-xs q-px-xs">Total Rental Fee</div>
                <div class="col-7 q-py-xs q-px-xs">PHP {{ this.invoice.totalRentalFee }}</div>
                <div class="col-5 text-bold q-py-xs q-px-xs" v-if="this.invoice.isPaid">Remaining Balance</div>
                <div class="col-7 q-py-xs q-px-xs" v-if="this.invoice.isPaid">PHP {{ this.invoice.operatorFee }}</div>
            </div>
            <div class="text-h5 text-bold q-mt-sm q-pl-md" v-if="!this.invoice.isPaid">
                To collect
            </div>
            <q-img src="/line.png" style="height: 8px;" v-if="!this.invoice.isPaid"/>
            <div class="row q-ml-md q-mt-lg" v-if="!this.invoice.isPaid">
                <div class="col-5 text-bold q-py-xs q-px-xs">Reservation Fee</div>
                <div class="col-7 q-py-xs q-px-xs">PHP {{ this.invoice.reservationFee - this.invoice.vatCharge}}</div>
                <div class="col-5 text-bold q-py-xs q-px-xs">VAT Charge (12%)</div>
                <div class="col-7 q-py-xs q-px-xs">+ PHP {{ this.invoice.vatCharge }}</div>
                <div class="col-5 text-bold q-py-xs q-px-xs">Remaining Balance</div>
                <div class="col-7 q-py-xs q-px-xs">PHP {{ this.invoice.operatorFee }}</div>
                <div class="col-12 q-py-xs q-px-xs">The <b>Remaining Balance</b> will be paid upon pick up of the vehicle. You must pay the <b>Reservation Fee</b> to reserve the vehicle on the date of your choice.</div>
            </div>
            <div class="text-h5 text-bold q-mt-sm q-pl-md" v-if="!this.invoice.isPaid">
                Amount to Pay
            </div>
            <q-img src="/line.png" style="height: 8px;"/>
            <div class="row q-ml-md q-mt-lg" v-if="this.invoice.isPaid">
                <div class="col-12 q-py-xs q-px-xs">The reservation fee of this booking was already paid. If there are any additional extensions made for this booking, it will appear here.</div>
            </div>
            <div class="text-center" v-if="!this.invoice.isPaid">
                <p class="text-h4 text-bold text-green q-ml-md q-mt-lg">PHP {{ this.invoice.reservationFee }}</p>
            </div>
            <div class="text-center" v-if="!this.invoice.isPaid && this.showPaymentMethod">
                <p><b>Choose your preferred payment method</b></p>
                <q-radio id="payment-card" v-model="method" :val="paymentMethods.card"/> <q-img src="/payment-card.png" for="payment-card" style="width: 100px; height: 30px" />
                <q-radio id="payment-gcash" v-model="method" :val="paymentMethods.gcash" /> <q-img src="/payment-gcash.webp" style="width: 100px; height: 30px" />
            </div>
            <div class="text-center q-py-lg" v-if="!this.invoice.isPaid && this.showPaymentMethod">
                <q-btn color="red" style="width: 100px" label="Continue" @click="$emit('selected-payment-method', this.method)"/>
            </div>
        </q-card-section>
</template>
<script>
export default {
    name: 'PaymentDetailsBooking',
    props: {
        showPaymentMethod: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            invoice: this.$store.state.rentalDetails,
            vehicle: this.$store.state.vehicle,
            method: this.constants.paymentMethods.gcash,
            paymentMethods: this.constants.paymentMethods,
            invoiceTypes: this.constants.invoiceTypes
        }
    }
}
</script>