<template>
    <div>
        <div style="height: 80vh" class="row justify-center items-center">
            <div>
                <div class="text-center">
                    <q-icon name="check" color="red" size="5rem" />
                    <div class="text-h5 text-bold q-pa-md">Your {{ term }} is now confirmed</div>
                    <div class="w-100 text-h6">The payment amounting to PHP {{ this.invoice.reservationFee }} was successfully made.</div>
                    <br>
                </div>
                <q-img src="/line.png" style="height: 8px;"/>
                <br>
                <div class="row q-ml-md q-mt-lg">
                    <div class="col-3 text-bold q-py-xs q-px-xs">Booking Code</div>
                    <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.bookingCode }}</div>
                    <div class="col-3 text-bold q-py-xs q-px-xs">Name of Customer</div>
                    <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.customerName }}</div>
                    <div class="col-3 text-bold q-py-xs q-px-xs">Pick Up Date</div>
                    <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.pickupDate }}</div>
                    <div class="col-3 text-bold q-py-xs q-px-xs">Return Date</div>
                    <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.estimatedReturnDate }}</div>
                    <div class="col-3 text-bold q-py-xs q-px-xs" v-if="this.invoice.type != this.constants.invoiceTypes.booking">New Return Date</div>
                    <div class="col-9 q-py-xs q-px-xs" v-if="this.invoice.type != this.constants.invoiceTypes.booking">{{ this.invoice.extension.newReturnDate }}</div>
                    <div class="col-3 text-bold q-py-xs q-px-xs" v-if="this.invoice.type != this.constants.invoiceTypes.booking">Additional Hours</div>
                    <div class="col-9 q-py-xs q-px-xs" v-if="this.invoice.type != this.constants.invoiceTypes.booking">+ {{ this.invoice.extension.hours }} hour(s)</div>                    
                    <div class="col-3 text-bold q-py-xs q-px-xs">Declared Destination</div>
                    <div class="col-9 q-py-xs q-px-xs">{{ this.invoice.destination }}</div>
                </div>
                <br>
                <q-img src="/line.png" style="height: 8px;"/>
                <br>
                <div class="row q-ml-md q-mt-lg">
                    <div class="col-3 text-bold q-py-xs q-px-xs">Vehicle</div>
                    <div class="col-9 q-py-xs q-px-xs">{{ this.vehicle.brand }} {{ this.vehicle.name }} {{ this.vehicle.year }}</div>
                    <div class="col-3 text-bold q-py-xs q-px-xs">Seating Capacity</div>
                    <div class="col-9 q-py-xs q-px-xs">{{ this.vehicle.seats }}</div>
                    <div class="col-3 text-bold q-py-xs q-px-xs">Transmission</div>
                    <div class="col-9 q-py-xs q-px-xs">{{ this.vehicle.transmission }}</div>
                    <div class="col-3 text-bold q-py-xs q-px-xs">Type</div>
                    <div class="col-9 q-py-xs q-px-xs">{{ this.vehicle.type }}</div>
                </div>
                <q-img src="/line.png" style="height: 8px;" v-if="this.invoice.type == this.constants.invoiceTypes.booking"/>
                <div class="row q-ml-md q-mt-lg" v-if="this.invoice.type == this.constants.invoiceTypes.booking">
                    <div class="col-5 text-bold q-py-xs q-px-xs">{{ this.vehicle.type }} for {{ this.invoice.duration }} day(s)</div>
                    <div class="col-7 q-py-xs q-px-xs">PHP {{ this.invoice.ratePerDay }}</div>
                    <div class="col-5 text-bold q-py-xs q-px-xs" v-if="this.invoice.voucher">Voucher ({{ this.invoice.voucher.code }})</div>
                    <div class="col-7 q-py-xs q-px-xs" v-if="this.invoice.voucher">- PHP {{ this.invoice.voucher.value }}</div>
                    <div class="col-5 text-bold q-py-xs q-px-xs">Total Rental Fee</div>
                    <div class="col-7 q-py-xs q-px-xs">PHP {{ this.invoice.totalRentalFee }}</div>
                </div>
                <q-img src="/line.png" style="height: 8px;" v-if="this.invoice.type == this.constants.invoiceTypes.booking"/>
                <div class="row q-ml-md q-mt-lg" v-if="this.invoice.type == this.constants.invoiceTypes.booking">
                    <div class="col-5 text-bold q-py-xs q-px-xs">Reservation Fee inclusive of VAT and other charges (Paid)</div>
                    <div class="col-7 q-py-xs q-px-xs">PHP {{ this.invoice.reservationFee }}</div>
                    <div class="col-5 text-bold q-py-xs q-px-xs">Remaining Balance</div>
                    <div class="col-7 q-py-xs q-px-xs">PHP {{ this.invoice.operatorFee }}</div>
                    <div class="col-12 q-py-xs q-px-xs" v-if="this.invoice.type == this.constants.invoiceTypes.booking">The <b>Remaining Balance</b> will be paid upon pick up of the vehicle. The <b>Reservation Fee</b> was already settled.</div>
                </div>
                <q-img src="/line.png" style="height: 8px;" v-if="this.invoice.type == this.constants.invoiceTypes.extension"/>
                <div class="row q-ml-md q-mt-lg" v-if="this.invoice.type == this.constants.invoiceTypes.extension">
                    <div class="col-5 text-bold q-py-xs q-px-xs">Reservation Fee inclusive of VAT and other charges (Paid)</div>
                    <div class="col-7 q-py-xs q-px-xs">PHP {{ this.invoice.extensionInvoice.reservation_fee }}</div>
                    <div class="col-5 text-bold q-py-xs q-px-xs">Remaining Balance</div>
                    <div class="col-7 q-py-xs q-px-xs">PHP {{ this.invoice.extensionInvoice.operator_fee }}</div>
                </div>
                <br>
                <div class="q-ml-md">
                    <div class="w-100">We have sent the confirmation details of your {{ term }} to your email.</div>
                    <div class="w-100">You can take a screenshot of this page and send it to your booking agent to verify the transaction.</div>
                    <div class="q-mt-xl w-100 text-bold q-mb-xl">You may now close this page.</div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    name: 'PaymentConfirmed',
    created() {
        if (this.invoice.type != this.constants.invoiceTypes.booking) {
            this.term = 'extension';
        }
        localStorage.clear();
    },
    data() {
        return {
            invoice: this.$store.state.rentalDetails,
            vehicle: this.$store.state.vehicle,
            method: this.constants.paymentMethods.gcash,
            paymentMethods: this.constants.paymentMethods,
            term: 'booking'
        }
    }
}
</script>