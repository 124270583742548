<template>
    <q-card flat style="width: 100%">
        <PaymentDetailsBooking v-if="this.invoice.type == this.invoiceTypes.booking" v-on:selected-payment-method="processSelectedPaymentMethod"/>
        <PaymentDetailsFullExtension v-else-if="this.invoice.type == this.invoiceTypes.fullExtension" v-on:selected-payment-method="processSelectedPaymentMethod"/>
        <PaymentDetailsPartialExtension v-else v-on:selected-payment-method="processSelectedPaymentMethod"/>
    </q-card>
</template>

<script>
import PaymentDetailsBooking from '@/components/PaymentHome/PaymentDetails/PaymentDetailsBooking'
import PaymentDetailsFullExtension from '@/components/PaymentHome/PaymentDetails/PaymentDetailsFullExtension'
import PaymentDetailsPartialExtension from '@/components/PaymentHome/PaymentDetails/PaymentDetailsPartialExtension'

export default {
    name: 'PaymentDetails',
    components: {
        PaymentDetailsBooking,
        PaymentDetailsFullExtension,
        PaymentDetailsPartialExtension
    },
    data() {
        return {
            invoice: this.$store.state.rentalDetails,
            vehicle: this.$store.state.vehicle,
            method: this.constants.paymentMethods.gcash,
            paymentMethods: this.constants.paymentMethods,
            invoiceTypes: this.constants.invoiceTypes
        }
    },
    methods: {
        processSelectedPaymentMethod(method) {
            this.$emit('selected-payment-method', method);
        }
    }
}
</script>