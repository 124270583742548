<template>
  <div class="row justify-center items-center" style="height: 400px">
    <div v-if="isLoading && !isPaid" class="text-center" style="height: 100px; width: 400px">
      <q-spinner
        color="red"
        size="3em"
        :thickness="4"
        class="q-mb-lg"
      />
      <p class="text-h5 text-bold">{{ loadingSection.title }}</p>
      <p>{{ loadingSection.message }}</p>
    </div>
    <PaymentConfirmed v-else-if="isPaid && !isLoading"/>
  </div>
</template>

<script>
import PaymentConfirmed from '@/components/PaymentConfirmed'
export default {
    name: 'PaymentEwalletConfirm',
    components: {
      PaymentConfirmed
    },
    created() {
        if (this.$route.query.origin == 'app') {
          window.parent.postMessage('Gcash-auth-ok', '*');
          return;
        }
        this.$store.dispatch('checkSource').then((response) => {
          if (response.status === this.constants.paymentResponses.chargeable) {
            this.isLoading = false;
            this.transact();
          }
          if (response.status === this.constants.paymentResponses.pending) {
            this.isLoading = false;
            window.location = response.checkoutUrl;
          }
          if (response.status === this.constants.paymentResponses.paid || response.status === this.constants.paymentResponses.alreadyPaid) {
            this.isLoading = false;
            this.isPaid = true;
          }
        }).catch((err) => {
          console.log(err)
          this.isLoading = false;
          this.isPaid = false;
          this.$q.dialog({
            title: 'Authorization Failed',
            message: 'The authorization process has failed. Please try again later.'
          }).onOk(() => {
            localStorage.clear();
            this.$router.push({name: 'PaymentHome'});
          }).onCancel(() => {
            localStorage.clear();
            this.$router.push({name: 'PaymentHome'});
          }).onDismiss(() => {
            localStorage.clear();
            this.$router.push({name: 'PaymentHome'});
          })
        });
        if (this.invoice.type != this.constants.invoiceTypes.booking) {
          this.term = 'extension';
        }
    },
    data() {
      return {
        isLoading: true,
        isPaid: false,
        invoice: this.$store.state.rentalDetails,
        loadingSection: {
          title: 'Verifying Authorization',
          message: 'Please wait for a while and do not close this window.'
        },
        term: 'reservation'
      }
    },
    methods: {
      /**
       * Proceeds to transact the source and attach it as a payment.
       */
      transact() {
        this.loadingSection.title = 'Processing Booking..';
        this.loadingSection.message = 'Please wait while we process your booking.';
        this.isLoading = true;
        this.$store.dispatch('transact').then((response) => {
          switch (response.status) {
            case this.constants.paymentResponses.paid:
              this.isLoading = false;
              this.isPaid = true;
              break;
            case this.constants.paymentResponses.alreadyPaid:
              this.isLoading = false;
              this.isPaid = true;
              break;
          }
        }).catch(() => {
          this.isLoading = false;
          this.isPaid = false;
          this.$q.dialog({
            title: 'Cannot Proceed to Payment',
            message: 'There was an error while trying to process your payment. Please try again later.'
          }).onOk(() => {
            localStorage.clear();
            this.$router.push({name: 'PaymentHome'});
          }).onCancel(() => {
            localStorage.clear();
            this.$router.push({name: 'PaymentHome'});
          }).onDismiss(() => {
            localStorage.clear();
            this.$router.push({name: 'PaymentHome'});
          });
        });
      },
      cancel() {
        localStorage.clear();
        this.$router.push('/');
      }
    }
}
</script>