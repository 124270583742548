<template>
  <div>
    <div v-if="isOnFinal && !isPaid && !authentication.showDialog" class="row justify-center items-center" style="height: 400px">
      <div class="text-center" style="height: 100px; width: 400px">
        <q-spinner
          color="red"
          size="3em"
          :thickness="4"
          class="q-mb-lg"
        />
        <p class="text-h5 text-bold">Processing Booking..</p>
        <p>Please wait while we process your booking.<br>Do not close this window.</p>
      </div>
    </div>
    <div v-if="!isOnFinal && authentication.showDialog && !isPaid" class="q-mt-xl q-pa-md">
      <iframe
        :src="authentication.url"
        width="100%"
        height="400"
        frameborder="0"
      >
      </iframe>
    </div>
    <div v-if="!isOnFinal && !authentication.showDialog && !isPaid">
     <div class="text-center q-pa-sm">
        <q-img src="/payment-card.png" for="payment-card" style="width: 200px; height: auto" />
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <q-card flat>
            <q-card-section>
              <div class="text-h5 text-bold q-mt-sm q-pl-md">
                Enter Card Details
              </div>
              <q-img src="/line.png" style="height: 8px;"/>
              <div class="row">
                <div class="col-12 q-pa-sm">
                  <q-input 
                    outlined 
                    v-model="billingInformation.name" 
                    label="Cardholder Name" 
                    :dense="dense" 
                    :error="inputFields.name.hasError"
                    :error-message="inputFields.name.errorText"
                    :disable="!allowInputs"                     
                  />
                </div>
                <div class="col-md-6 col-12 q-pa-sm">
                  <q-input
                    type="email"
                    outlined 
                    v-model="billingInformation.emailAddress" 
                    label="Email Address" 
                    :dense="dense"
                    :error="inputFields.email.hasError"
                    :error-message="inputFields.email.errorText"
                    :disable="!allowInputs"                       
                  />
                </div>
                <div class="col-md-6 col-12 q-pa-sm">
                  <q-input
                    outlined 
                    v-model="billingInformation.mobileNumber"  
                    label="Phone Number" 
                    :dense="dense"
                    :error="inputFields.phone.hasError"
                    :error-message="inputFields.phone.errorText"
                    :disable="!allowInputs" 
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 q-pa-sm">
                  <q-input 
                    outlined 
                    v-model="cardDetails.number" 
                    label="Enter Card Number" 
                    :dense="dense" 
                    mask="################"
                    fill-mask=""
                    :error="inputFields.cardNumber.hasError"
                    :error-message="inputFields.cardNumber.errorText"
                    :disable="!allowInputs" 
                  />
                </div>
                <div class="col-md-4 col-6 q-pa-sm">
                  <q-input 
                    outlined 
                    v-model="cardDetails.expMonth" 
                    label="Expiration Month" 
                    :dense="dense" 
                    mask="##"
                    fill-mask=" "
                    :error="inputFields.expMonth.hasError"
                    :error-message="inputFields.expMonth.errorText"
                    :disable="!allowInputs"
                  />

                </div>
                <div class="col-md-4 col-6 q-pa-sm">
                  <q-input 
                    outlined 
                    v-model="cardDetails.expYear" 
                    label="Expiration Year" 
                    :dense="dense" 
                    mask="##"
                    fill-mask=" "
                    :error="inputFields.expYear.hasError"
                    :error-message="inputFields.expYear.errorText"
                    :disable="!allowInputs" 
                  />
                </div>
                <div class="col-md-4 col-12 q-pa-sm">
                  <q-input 
                    outlined 
                    v-model="cardDetails.securityCode" 
                    label="Security Code (CVC)"
                    :dense="dense" 
                    mask="###"
                    fill-mask=" "
                    :error="inputFields.cvc.hasError"
                    :error-message="inputFields.cvc.errorText"
                    :disable="!allowInputs"                     
                  />
                </div>
                <div class="lt-md col-12 q-pa-sm">
                  <q-banner outline class="bg-grey-3 text-black text-right">
                    PHP {{ this.invoice.reservationFee}}
                  </q-banner>
                </div>
                <div class="col-12 q-pa-sm">
                   <q-banner class="bg-red text-white q-mt-md" v-if="showGenericError">
                    <template v-slot:avatar>
                      <q-icon name="payment" color="white" />
                    </template>
                    {{ genericErrorMessage }}
                    <template v-slot:action>
                      <q-btn flat color="white" label="Confirm" @click="showGenericError = !showGenericError" />
                    </template>
                  </q-banner>
                </div>
              </div>
              <div class="row">
                <div class="lt-md col-12">
                  <q-toggle color="red" size="xl" label="Show Booking Details" v-model="showBookingDetails" />
                </div> 
                <div class="lt-md col-12" style="padding: 0">
                  <q-slide-transition>
                    <div v-show="showBookingDetails">
                      <q-card flat style="width: 100%; padding: 0">
                        <PaymentDetailsBooking :showPaymentMethod="false" v-if="this.invoice.type == this.invoiceTypes.booking" />
                        <PaymentDetailsFullExtension :showPaymentMethod="false" v-else-if="this.invoice.type == this.invoiceTypes.fullExtension" />
                        <PaymentDetailsPartialExtension :showPaymentMethod="false" v-else />
                      </q-card>
                    </div>
                  </q-slide-transition>
                </div>
              </div>
              <div class="text-center q-py-md">
                <q-btn color="white" text-color="black" label="Back" @click="$router.push('/')" />
                <q-btn color="red" class="q-ml-sm q-mr-md" @click="payNow" label="Pay Now" />
              </div>     
            </q-card-section>
          </q-card>
        </div>
        <div class="gt-md col-md-6 col-12">
          <q-card style="width: 100%">
            <PaymentDetailsBooking :showPaymentMethod="false" v-if="this.invoice.type == this.invoiceTypes.booking" />
            <PaymentDetailsFullExtension :showPaymentMethod="false" v-else-if="this.invoice.type == this.invoiceTypes.fullExtension" />
            <PaymentDetailsPartialExtension :showPaymentMethod="false" v-else />
          </q-card>
        </div>
      </div>
    </div>
    <PaymentConfirmed v-if="isPaid"/>
  </div>
</template>

<script>
import PaymentDetailsBooking from '@/components/PaymentHome/PaymentDetails/PaymentDetailsBooking'
import PaymentDetailsFullExtension from '@/components/PaymentHome/PaymentDetails/PaymentDetailsFullExtension'
import PaymentDetailsPartialExtension from '@/components/PaymentHome/PaymentDetails/PaymentDetailsPartialExtension'
import PaymentConfirmed from '@/components/PaymentConfirmed'

export default {
    name: 'PaymentMethodCard',
    components: {
        PaymentDetailsBooking,
        PaymentDetailsFullExtension,
        PaymentDetailsPartialExtension,
        PaymentConfirmed
    },
    created() {
      // If no payment intent id attached, return to home screen
      if (this.$store.state.paymentIntentId === null) {
        localStorage.clear();
        this.$router.push({name: 'PaymentHome'});
        console.error('There was no payment intent found on the website. Please try again.')
      }

      // Event listener after user finishes their 3DS authentication
      window.addEventListener('message', (ev) => {
        if (ev.data === '3DS-authentication-complete') {
          // Do not allow inputs since we are going to process the final payment
          this.allowInputs = false;

          // Hide the iframe
          this.authentication.showDialog = false;

          // Show the final screen
          this.isOnFinal = true;

          // Perform final API call to process the booking.
          this.$store.dispatch('continue').then((response) => {
            switch (response.status) {
              case 'success':
                this.allowInputs = false;
                this.isOnFinal = false;
                this.isPaid = true;
                localStorage.clear();
                break;
              case 'already_paid':
                this.$q.dialog({
                  title: 'Booking Paid',
                  message: 'This booking was already paid and there are no other pending payments.'
                }).onOk(() => {
                  localStorage.clear();
                  this.$router.push({name: 'PaymentHome'});
                }).onCancel(() => {
                  localStorage.clear();
                  this.$router.push({name: 'PaymentHome'});
                }).onDismiss(() => {
                  localStorage.clear();
                  this.$router.push({name: 'PaymentHome'});
                })
                break;
            }
          }).catch(() => {
              this.isOnFinal = false;
              this.$q.dialog({
                title: 'There was a mistake',
                message: 'There has been an issue while trying to process your payment. Please select a different card or choose another payment method.'
              }).onOk(() => {
                localStorage.clear();
                this.$router.push({name: 'PaymentHome'});
              }).onCancel(() => {
                localStorage.clear();
                this.$router.push({name: 'PaymentHome'});
              }).onDismiss(() => {
                localStorage.clear();
                this.$router.push({name: 'PaymentHome'});
              })
          });
        }
      })
    },
    data() {
      return {
        genericErrorMessage: 'The card has been denied by the issuing bank. Please use another card or choose another payment method.',
        showGenericError: false,
        isOnFinal: false,
        isPaid: false,
        showBookingDetails: false,
        allowInputs: true,
        invoice: this.$store.state.rentalDetails,
        vehicle: this.$store.state.vehicle,
        invoiceTypes: this.constants.invoiceTypes,
        confirmation: false,
        cardDetails: {
          number: "",
          expMonth: null,
          expYear: null,
          securityCode: null
        },
        address: {
          addressLine1: "",
          addressLine2: null,
          city: '',
          state: '',
          postalCode: null,
          country: '',
        },
        billingInformation: {
          name: "",
          emailAddress: "",
          mobileNumber: ""
        },
        authentication: {
          showDialog: false,
          url: 'https://example.com'
        },
        inputFields: {
          cardNumber: {
            pointer: 'details.card_number',
            attribute: 'Card Number',
            hasError: false,
            errorText: ''
          },
          expMonth: {
            pointer: 'details.exp_month',
            attribute: 'Expiration Month',
            hasError: false,
            errorText: ''
          },
          expYear: {
            pointer: 'details.exp_year',
            attribute: 'Expiration Year',
            hasError: false,
            errorText: ''
          },
          cvc: {
            pointer: 'details.cvc',
            attribute: 'CVC',
            hasError: false,
            errorText: ''
          },
          name: {
            pointer: 'details.name',
            attribute: 'Cardholder Name',
            hasError: false,
            errorText: ''
          }, 
          email: {
            pointer: 'details.email',
            attribute: 'Email',
            hasError: false,
            errorText: ''
          }, 
          phone: {
            pointer: 'details.phone',
            attribute: 'Phone',
            hasError: false,
            errorText: ''
          }                                                                   
        }
      }
    },
    methods: {
      /**
       * Triggers when the Pay Now button has been pressed.
       */
      payNow() {
        /**
         * Will now submit the card details and create a payment method
         */
        this.createPaymentMethod(this.$store.state.paymentIntentId, {
          address: this.address,
          cardDetails: this.cardDetails,
          billingInformation: this.billingInformation
        });
      },

      /**
       * Create payment method
       */
      createPaymentMethod(paymentIntentId, paymentDetails) {
        // Lock all input fields
        this.allowInputs = false;
        this.$q.loading.show({
          boxClass: 'bg-grey-2 text-grey-9',
          message: 'Validating card details..'
        });
        this.resetValidations();
        this.$store.dispatch('createPaymentMethod', {
          paymentIntentId: paymentIntentId,
          address: paymentDetails.address,
          cardDetails: paymentDetails.cardDetails,
          billingInformation: paymentDetails.billingInformation
        }).then((response) => {
          this.$q.loading.show({
            boxClass: 'bg-grey-2 text-grey-9',
            message: 'Performing transaction..'
          });
          // If the card has been successfully submitted to Paymongo then let's use
          // the card for the payment and attach it
          if (response.status === 'card_submitted') {
            this.attachPaymentMethod(paymentIntentId, response.paymentMethodId);
          } else if (response.status === 'already_paid') {
            this.allowInputs = true;
            this.isPaid = true;
          }
        }).catch((err) => {
          this.$q.loading.hide();
          this.allowInputs = true;
          if (err.response.status == 400) {
            this.handleErrors(err.response.data.errors);
            this.$q.dialog({
              message: 'Please check your inputs.'
            }).onOk(() => {
              // console.log('OK')
            }).onCancel(() => {
              // console.log('Cancel')
            }).onDismiss(() => {
              // console.log('I am triggered on both OK and Cancel')
            }) 
          } else {
            console.error(err);
            this.$q.dialog({
              title: 'We have encountered an error',
              message: 'Sorry but we have encountered an error while trying to transmit the details. Please try again later.'
            }).onOk(() => {
              // console.log('OK')
            }).onCancel(() => {
              // console.log('Cancel')
            }).onDismiss(() => {
              // console.log('I am triggered on both OK and Cancel')
            })
          }
        });
      },
    
      /**
       * Attach the payment method to the payment intent. At this event
       * the payment will now be processed.
       */
      attachPaymentMethod(paymentIntentId, paymentMethodId) {
        this.$store.dispatch('attachPaymentMethod', {
          paymentIntentId: paymentIntentId,
          paymentMethodId: paymentMethodId
        }).then((response) => {
          this.$q.loading.hide();
          switch (response.status) {
            case this.constants.paymentResponses.authenticationRequired:
              this.authentication.url = response.url;
              this.authentication.showDialog = true;
              break;
            case this.constants.paymentResponses.paymentFailed:
              this.$q.dialog({
                title: 'Payment Failed',
                message: 'The payment attempt failed. No deductions made and booking is still waiting for payment. Please try again later.'
              }).onOk(() => {
                localStorage.clear();
                this.$router.push({name: 'PaymentHome'});
              }).onCancel(() => {
                localStorage.clear();
                this.$router.push({name: 'PaymentHome'});
              }).onDismiss(() => {
                localStorage.clear();
                this.$router.push({name: 'PaymentHome'});
              })
              break;
            case this.constants.paymentResponses.processing:
              // Perform a recall after 3 seconds.
              break;
            case 'already_paid':
              this.isPaid = true;
              localStorage.clear();
              break;
            default:
              this.isPaid = true;
              localStorage.clear();
          }
        }).catch((err) => {
          this.$q.loading.hide();
          // Card payment is closed
          if (err.response.status == 404 && err.response.data.status == 'payment_not_available') {
            this.$q.dialog({
              title: 'Card Payment Not Available',
              message: 'We are sorry but the card payment method is not available at this moment. Please try again later.'
            }).onOk(() => {
              // console.log('OK')
            }).onCancel(() => {
              // console.log('Cancel')
            }).onDismiss(() => {
              // console.log('I am triggered on both OK and Cancel')
            })
          } else if (err.response.status == 400 && err.response.data.status == 'generic_decline') {
            this.showGenericError = true;
            this.allowInputs = true;
            this.genericErrorMessage = err.response.data.data.message;
          } else {
            this.$q.dialog({
              title: 'Oooppss..',
              message: 'We have failed to complete the process. Please try again later.'
            }).onOk(() => {
              localStorage.clear();
              this.$router.push({name: 'PaymentHome'});
            }).onCancel(() => {
              localStorage.clear();
              this.$router.push({name: 'PaymentHome'});
            }).onDismiss(() => {
              localStorage.clear();
              this.$router.push({name: 'PaymentHome'});
            })
          }
        });
      },

      /**
       * Handles the card payment errors properly and display it on the page
       * for users.
       */
      handleErrors(errors) {
        errors.forEach((error) => {
          switch (error.source.attribute) {
            case 'card_number':
              this.inputFields.cardNumber.errorText = error.detail.replace(
                error.source.pointer,
                this.inputFields.cardNumber.attribute
              );
              this.inputFields.cardNumber.hasError = true;
            break;
            case 'exp_month':
              this.inputFields.expMonth.errorText = error.detail.replace(
                error.source.pointer,
                this.inputFields.expMonth.attribute
              );
              this.inputFields.expMonth.hasError = true;
            break;
            case 'exp_year':
              this.inputFields.expYear.errorText = error.detail.replace(
                error.source.pointer,
                this.inputFields.expYear.attribute
              );
              this.inputFields.expYear.hasError = true;
            break;
            case 'cvc':
              this.inputFields.cvc.errorText = error.detail.replace(
                error.source.pointer,
                this.inputFields.cvc.attribute
              );
              this.inputFields.cvc.hasError = true;
            break;
            case 'name':
              this.inputFields.name.errorText = error.detail.replace(
                error.source.pointer,
                this.inputFields.name.attribute
              );
              this.inputFields.name.hasError = true;
            break;
            case 'email':
              this.inputFields.email.errorText = error.detail.replace(
                error.source.pointer,
                this.inputFields.email.attribute
              );
              this.inputFields.email.hasError = true;
            break;    
            case 'phone':
              this.inputFields.phone.errorText = error.detail.replace(
                error.source.pointer,
                this.inputFields.phone.attribute
              );
              this.inputFields.phone.hasError = true;
            break;                                                                                       
          }
        })
      },

      /**
       * Resets all the validation messages.
       */
      resetValidations() {
        this.inputFields.cardNumber.hasError = false;
        this.inputFields.cardNumber.errorText = '';

        this.inputFields.expMonth.hasError = false;
        this.inputFields.expMonth.errorText = '';      

        this.inputFields.expYear.hasError = false;
        this.inputFields.expYear.errorText = '';    

        this.inputFields.cvc.hasError = false;
        this.inputFields.cvc.errorText = '';    

        this.inputFields.name.hasError = false;
        this.inputFields.name.errorText = '';    

        this.inputFields.email.hasError = false;
        this.inputFields.email.errorText = '';    

        this.inputFields.phone.hasError = false;
        this.inputFields.phone.errorText = '';    
      }
    },
}
</script>